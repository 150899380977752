var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_leasing_payment_plan_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formState,
            "label-align": "left",
            layout: "horizontal",
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "data-testid": "leasing-payment-plan-form"
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_payment_date"),
                        prop: "paymentDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_start_date")
                            }),
                            _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_end_date")
                            })
                          ]
                        },
                        model: {
                          value: _vm.formState.paymentDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "paymentDate", $$v)
                          },
                          expression: "formState.paymentDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_bank_name"),
                        prop: "companyBank"
                      }
                    },
                    [
                      _c("SelectCompanyBank", {
                        model: {
                          value: _vm.formState.companyBank,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "companyBank", $$v)
                          },
                          expression: "formState.companyBank"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier_type"),
                        prop: "supplierType"
                      }
                    },
                    [
                      _c("SelectMasterType", {
                        attrs: {
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_supplier_type")
                          }),
                          name: "SUPPLIER_TYPE"
                        },
                        model: {
                          value: _vm.formState.supplierType,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "supplierType", $$v)
                          },
                          expression: "formState.supplierType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_lessor_name"),
                        prop: "lessor"
                      }
                    },
                    [
                      _c("SelectLessor", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.lessor,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "lessor", $$v)
                          },
                          expression: "formState.lessor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_leasing_number"),
                        prop: "leasing"
                      }
                    },
                    [
                      _c("SelectLeasing", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.formState.leasing,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "leasing", $$v)
                          },
                          expression: "formState.leasing"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.loading.submit
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      columns: _vm.columns,
                      "data-source": _vm.dtReport.data,
                      "row-class-name": function(_, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      loading: _vm.loading.submit,
                      scroll: { x: 1900 },
                      pagination: {
                        showTotal: function() {
                          return _vm.$t("lbl_total_items", {
                            total: _vm.dtReport.totalElements
                          })
                        },
                        showSizeChanger: true,
                        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                        current: _vm.pagination.page,
                        pageSize: _vm.pagination.limit,
                        total: _vm.dtReport.totalElements
                      }
                    },
                    on: { change: _vm.onChangeTable },
                    scopedSlots: _vm._u([
                      {
                        key: "lessorName",
                        fn: function(text) {
                          return [
                            _c(
                              "a-tooltip",
                              { attrs: { title: text, placement: "top" } },
                              [_vm._v(" " + _vm._s(text) + " ")]
                            )
                          ]
                        }
                      },
                      {
                        key: "unitCode",
                        fn: function(text) {
                          return [
                            _c(
                              "a-tooltip",
                              { attrs: { title: text, placement: "left" } },
                              [_vm._v(" " + _vm._s(text) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_grand_total"),
                      value: _vm.dtReport.totalInstallment
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16], justify: "end" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "btn-download",
                        type: "primary",
                        "html-type": "button",
                        loading: _vm.loading.download
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }