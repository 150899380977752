import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  LeasingPaymentPlanReportPageDto,
  LeasingPaymentPlanReportParams,
} from "@/models/interface/leasing-payment-plan";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class LeasingPaymentPlanService extends HttpClient {
  getList(
    params?: LeasingPaymentPlanReportParams
  ): Promise<LeasingPaymentPlanReportPageDto> {
    return this.get<LeasingPaymentPlanReportPageDto>(
      Api.LeasingPaymentPlanReportList,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: LeasingPaymentPlanReportParams): Promise<Blob> {
    return this.get<Blob>(Api.LeasingPaymentPlanReportDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const leasingPaymentPlanService = new LeasingPaymentPlanService();
